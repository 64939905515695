angular
    .module('annexaApp')
    .component('annexaBoxObjectDecree',{
        templateUrl: './components/sec/annexa-box-object-decree/annexa-box-object-decree.html',
        controller: ['SecFactory', '$filter', '$rootScope', 'ErrorFactory', '$state', '$scope', 'RestService', 'HelperService', 'AnnexaPermissionsFactory', 'GlobalDataFactory', 'Language', 'AnnexaFormlyFactory', 'CommonService', 'DialogsFactory', 'HeaderService', 'SecModals', 'DccumentsFactory', '$http', 'SignLocalFactory', function (SecFactory, $filter, $rootScope, ErrorFactory, $state, $scope, RestService, HelperService, AnnexaPermissionsFactory, GlobalDataFactory, Language, AnnexaFormlyFactory, CommonService, DialogsFactory, HeaderService, SecModals, DccumentsFactory, $http, SignLocalFactory) {
            //region General
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();
            vm.loggedUser = $rootScope.LoggedUser;
            vm.calculateCanEdit = function(){
	            var documentType = $rootScope.app.configuration.sec_document_types["SDR"];
	        	if(vm.decree && vm.decree.documents && documentType){
	        		_.forEach(vm.decree.documents, function(document){
	        			if(document.document && document.document.type && _.contains(documentType.id, document.document.type.id+"")){
	        				if(document.document.docStatus && _.contains(['NEW','NEW_AND_ATTACHMENT','REJECTED', 'COMPLETE'], document.document.docStatus)){
	        					vm.canEdit = true;
	        				}
	        			}
	        		});
	        	}
            }
        	vm.canEdit = vm.calculateCanEdit();
        	vm.isEdit = true;
        	//region Form

            vm.alerts = [];

            //endregion

            
    		$scope.$on('annexaBoxUpdateSaveDecree', function (event, args) {
	            if(args.origin && args.origin == 'annexa-box-object-decree') {
	            	vm.canEdit = vm.calculateCanEdit();
	                HeaderService.changeState($state.current, true);
	            }
	        });

   	        //endregion

            //region General Box
            var getEditPermissions = function(type) {
                switch (type) {
                    default:
                        var permissions = [];

                        if(AnnexaPermissionsFactory.havePermission(vm.editPerm)) {
                            permissions.push(vm.editPerm);
                        }

                        if(AnnexaPermissionsFactory.havePermission(vm.newPerm)) {
                            permissions.push(vm.newPerm);
                        }

                        return permissions;
                        break;
                }
            }
            
            vm.editDecreeDocument = function(){
            	var canEditDocument = false;
                var documentSDR = undefined;
            	var documentType = $rootScope.app.configuration.sec_document_types["SDR"];
            	if(vm.decree && vm.decree.documents && documentType){
	        		_.forEach(vm.decree.documents, function(document){
	        			if(document.document && document.document.type && _.contains(documentType.id, document.document.type.id+"")){
	        				if(document.document.docStatus && _.contains(['NEW_AND_ATTACHMENT','REJECTED'], document.document.docStatus)){
	        					canEditDocument = true;
	        					documentSDR = document.document;
	        				}
	        			}
	        		});
	        	}
            	if(canEditDocument && documentSDR){
            		DccumentsFactory.getEditOnlineUrl(documentSDR.idDocumentManager).then(function(data) {
                        window.location.href = data;
                    }).catch(function(error) {
                    	DialogsFactory.error('global.sec.literals.noDecreeDocument', 'Error');
                    });		
            	}else{
            		DialogsFactory.error('global.sec.literals.noDecreeDocument', 'Error');
            	}
            }
            
            vm.sendToSign = function(){
            	var canSendToSign = false;
            	var documentSDR = undefined;
            	var documentType = $rootScope.app.configuration.sec_document_types["SDR"];
            	if(vm.decree && vm.decree.documents && documentType){
	        		_.forEach(vm.decree.documents, function(document){
	        			if(document.document && document.document.type && _.contains(documentType.id, document.document.type.id+"")){
	        				if(document.document.docStatus && _.contains(['NEW_AND_ATTACHMENT','REJECTED', 'COMPLETE'], document.document.docStatus)){
	        					canSendToSign = true;
	        					documentSDR = document.document;
	        				}
	        			}
	        		});
	        	}
            	if(canSendToSign && documentSDR){
            		SignLocalFactory.sendToSignModal(undefined, [documentSDR]);
            	}else{
            		DialogsFactory.error('global.sec.literals.noDecreeDocument', 'Error');
            	}
            }
            
            vm.generateAmendment = function() {
                var modal = angular.copy(SecModals.amendmentProposal);
                modal.alerts = [];
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};

                var confirmGenerateAmendment = function () {
                    if (this.annexaFormly.model.modal_body.reason) {
                    	var secretariatObservations = this.annexaFormly.model.modal_body.reason;
                    	DialogsFactory.confirm('global.sec.literals.amendmentPopupProposal', 'global.sec.literals.confirmAmendmentProposal').then(function (dataAux) {
                        	SecFactory.updateStateProposal(vm.decree.proposal.id, 'AMENDMENT', secretariatObservations).then(function(data) {
                                if(data) {
                                	$state.transitionTo('annexa.sec.decrees');
                                }
                            }).catch(function(error) {
                            	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'updateStateDecree', error)});
                            })
                        }).catch(function (data) {
                        	//Empty
                        });        	
                        modal.close();
                    }
                };

                AnnexaFormlyFactory.showModal("modalAmendmentProposal", modal, confirmGenerateAmendment, false);
            };
            
            vm.cancelProposal = function() {
                var modal = angular.copy(SecModals.cancelProposal);
                modal.alerts = [];
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};

                var confirmCancelProposal = function () {
                    if (this.annexaFormly.model.modal_body.reason) {
                    	var secretariatObservations = this.annexaFormly.model.modal_body.reason;
                    	DialogsFactory.confirm('global.sec.literals.cancelPopupProposal', 'global.sec.literals.confirmCancelProposal').then(function (dataAux) {
                        	SecFactory.updateStateProposal(vm.decree.proposal.id, 'CANCELED', secretariatObservations).then(function(data) {
                                if(data) {
                                	$state.transitionTo('annexa.sec.decrees');
                                }
                            }).catch(function(error) {
                            	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'updateStateDecree', error)});
                            })
                        }).catch(function (data) {
                        	//Empty
                        });        	
                        modal.close();
                    }
                };

                AnnexaFormlyFactory.showModal("modalCancelProposal", modal, confirmCancelProposal, false);
            };
            
            $scope.$on('SendToSignModalFinished', function (event) {
  	    	  $state.reload();  
  	      	});
            //endregion 
            

            this.$onInit = function() {


                var propTypes = angular.copy(GlobalDataFactory.proposalTypes);
                vm.proposalTypes = $linq(propTypes).where("x => x.proposalTypeSubtype == 'DECREE'").toArray();
                
            	// Aqui nos creamos el formulario para una propuesta.
                vm.decreeFormFields = [];
                vm.decreeFormFields.push({ type: 'field', id: 'proposalType', fieldType: 'select', data: vm.proposalTypes, colClass: 'col-sm-12', required: true, label: 'global.sec.literals.proposalType', isReadOnly:false,
                		printFunction:  function(proposalType) {
                            if(proposalType) {
                            	return proposalType[vm.languageColumn];
                            } else {
                                return '';
                            }
                        }
                });
                vm.decreeFormFields.push({ type: 'field', id: 'decreeNumber', fieldType: 'text', colClass: 'col-sm-12', required: false, label: 'global.sec.literals.decreeNumber', isReadOnly:false});
                vm.decreeFormFields.push({ type: 'field', id: 'decreeDate', fieldType: 'date', colClass: 'col-sm-12', required: false, label: 'global.sec.literals.decreeDate', isReadOnly:false });
                vm.decreeFormFields.push({ type: 'field', id: 'secretariatObservations', fieldType: 'textarea', colClass: 'col-sm-12', required: false, label: 'global.literals.observations', isReadOnly:vm.canEdit });

                vm.newPerm = '';
            	vm.editPerm = '';
                //region Init Boxs

                vm.documentBox = {
                    content: ((vm.decree.documents)? $linq(vm.decree.documents).toArray() : []),
                    config: { documentTitle: undefined },
                    origin: 'annexa-box-object-decree'
                }

                vm.dossierBox = {
                    content: ((vm.decree.proposal && vm.decree.proposal.dossiers)? $linq(vm.decree.proposal.dossiers).select("x => x.dossier").toArray() : []),
                    config: {},
                    origin: 'annexa-box-object-decree'
                }

                vm.proposalBox = {
                    content: ((vm.decree.proposal)? [vm.decree.proposal] : []),
                    config: {},
                    origin: 'annexa-box-object-decree'
                }
                //endregion
            }
            //region decree Methods

        	vm.updateDecree = function(val, prop) {
            	vm.decree[prop] = val;
                
                RestService.update('./api/sec/decree/' + vm.decree.id, vm.decree)
                     .then(function (data) {
                    	$rootScope.$broadcast('annexaBoxUpdateSaveDecree', {origin: 'annexa-box-object-decree'});
                     }).catch(function (error) {
                         console.error(error);
                });
            }
            //endregion
            
        }],
        bindings: {
            redirectToList: '=',
            decree: '=?',
            preloadBoxes: '=?'
        }
    })
    .component('annexaBoxObjectDecreeHeader',{
        templateUrl: './components/sec/annexa-box-object-decree/annexa-box-object-decree-header.html',
        require: {
            decreeComponent: '^^annexaBoxObjectDecree'
        },
        controller:['$rootScope', '$filter', 'Language', 'SecFactory', '$scope', function($rootScope, $filter, Language, SecFactory, $scope) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            $scope.$on('annexaBoxUpdateSaveDecree', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-decree') {
                	vm.canEdit = vm.decreeComponent.calculateCanEdit();
                }
            });
            
            this.$onInit = function() {
            	vm.state = $linq(SecFactory.decreeStates).singleOrDefault(undefined, "x => x.id == 'PENDING'");
            	if(vm.decreeComponent.decree && vm.decreeComponent.decree.status){
            		vm.state = $linq(SecFactory.decreeStates).singleOrDefault(undefined, "x => x.id == '"+vm.decreeComponent.decree.status+"'");
           		}
            	vm.canEdit = vm.decreeComponent.calculateCanEdit();
            }
        }]
    })