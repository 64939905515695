angular
    .module('annexaApp')
    .component('annexaBoxProposal',{
        templateUrl: './components/sec/annexa-box-proposal/annexa-box-proposal.html',
        controller:['globalModals', 'Language', 'AnnexaEntityFactory', 'ModalService', '$rootScope', 'AnnexaObjectBoxFactory', 'AnnexaFormlyFactory', 'GlobalDataFactory', '$filter', '$scope', 'AnnexaPermissionsFactory', '$state', 'SecFactory', function(globalModals, Language, AnnexaEntityFactory, ModalService, $rootScope, AnnexaObjectBoxFactory, AnnexaFormlyFactory, GlobalDataFactory, $filter, $scope, AnnexaPermissionsFactory, $state, SecFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            vm.redirect = function (id) {
                window.open($state.href('annexa.sec.proposals.edit', { proposal: id }), '_blank');
            }
            
            vm.canShow = false;
            if(AnnexaPermissionsFactory.havePermission('view_proposals')) {
            	vm.canShow = true;
            }
            
            vm.getStatusTitle = function(proposal){
            	var state = $linq(SecFactory.proposalStates).singleOrDefault(undefined, "x => x.id == 'PENDING'");
            	if(proposal && proposal.state){
            		state = $linq(SecFactory.proposalStates).singleOrDefault(undefined, "x => x.id == '"+proposal.state+"'");
            	}
            	if(state){
            		return state.name;
            	}else{
            		return '';
            	}
            }
            
            vm.getStatusIcon = function(proposal){
            	var state = $linq(SecFactory.proposalStates).singleOrDefault(undefined, "x => x.id == 'PENDING'");
            	if(proposal && proposal.state){
            		state = $linq(SecFactory.proposalStates).singleOrDefault(undefined, "x => x.id == '"+proposal.state+"'");
            	}
            	if(state){
            		return 'fa fa-'+state.icon+' '+state.style+' fa-lg';
            	}else{
            		return '';
            	}
            }
            
        	this.$onInit = function () {
            	if(!vm.boxTitle) {
                    vm.boxTitle = 'global.sec.proposals.title';
                }
            	vm.emptyText = 'global.sec.literals.noDecreeProposals';
            }
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            new: '=',
            content: '=',
            isEdit: '=',
            config: '=',
            origin: '@',
            canEdit: '='
        }
    })